@import "~antd/dist/antd.less";
@import "antd/dist/antd.css";

.text-align-center {
  text-align: center;
}

.ant-drawer-body {
  padding: 0px !important;
  border: 8px solid #f7fff8;
}

.ant-modal-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3d3d3d;
}

.ant-layout-sider-trigger {
  position: absolute;
  top: 94vh;
  border-bottom: 1px solid #efefef;
}

.ant-table-tbody > tr > td {
  overflow: hidden;
}
// .ant-table-thead > tr > th,
// .ant-table-tbody > tr > td,
// .ant-table tfoot > tr > th,
// .ant-table tfoot > tr > td {
//   padding: 16px 8px;
// }

/* .ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  margin-left: 250px;
} */
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

table {
  table-layout: auto !important;
}

@primary-color: #39B54A;