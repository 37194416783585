.text_align_center{
    text-align: center;
}

.text_margin_top{
    margin-top: 20px;
}

.auth_login_span{
    color: #39B54A;
    cursor: pointer;
}