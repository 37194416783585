.camp-setup-text-Area-Custom {
    height: 150px;
  }

.camp-submit-setup{
    display: flex;
    gap: 20;
    margin-bottom: 30;
}

.camp-setup-action {
    display: flex;
    gap: 10;
}